import Vue from 'vue/dist/vue.esm'

// import Vue Globals


// import parent components
import NewsCarousel from '../components/NewsCarousel'

// import library.js - custom functions and methods
import './library'

document.addEventListener('DOMContentLoaded', () => {
    let element = document.getElementById("newscarousel")
    let news = element.dataset.news


    const app = new Vue({
        el: element,
        data: {
            news: JSON.parse(news),
        },
        template: '<NewsCarousel  :news="news"/>',
        components: {NewsCarousel},
    })
})
