// MyFilter.js
import Vue from 'vue/dist/vue.esm'
import moment from 'moment'
import Cookies from 'js-cookie'

var c = Cookies.get('locale')
if(c){
    c = c == 'en' ? 'en-gb' : c
} else {
    c = 'en-gb'
}

import 'moment/locale/de'
import 'moment/locale/en-gb'

moment.locale(c)

import Ads from 'vue-google-adsense'
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
Vue.use(Ads.InArticleAdsense)
Vue.use(Ads.InFeedAdsense)


const {
    convertIocCode,
    convertIso2Code,
    convertIso3Code
} = require("convert-country-codes");

convertIocCode("BRA");
// { iso2: 'BR', iso3: 'BRA' }
Vue.filter(
    'dateShowTime', value => {
        if (value) {
            return moment(String(value)).format('HH:mm')
        }
    });

Vue.filter(
    'dateShowTimeSec', value => {
        if (value) {
            return moment(String(value)).format('HH:mm:ss')
        }
    });

Vue.filter(
    'dateDatetime', value => {
        if (value) {
            return moment(String(value)).format('DD MMM, HH:mm')
        }
    });

Vue.filter(
    'dateShowWeek', value => {
        if (value) {
            return moment(String(value)).format('dddd, DD MMMM')
        }
    });

Vue.filter(
    'dateShort', value => {
        if (value) {
            return moment(String(value)).format('DD MMMM')
        }
    });

Vue.filter(
    'dateFull', value => {
        if (value) {
            return moment(String(value)).format('dddd, DD MMM, HH:mm')
        }
    });

Vue.filter('raceTitleLoc', race =>{
    if(row.includes("Z")){
        return this.$options.filters.dateShowTimeSec(row)
    } else {
        return row
    }
});

Vue.filter('getGndrRow', race =>{
});


Vue.mixin({
    methods: {
        isoA3: function (value) {
            var to2 = convertIocCode(value)
            if (!to2){
                return 'xx'
            } else {
                return to2['iso2'].toLowerCase()
            }
        }
    }
});
