<template lang="pug">
    .only-mobile
        .carousel.carousel-animated.carousel-animate-slide
            .carousel-container
                .carousel-item.has-background(v-for='nw in news', :key='nw.id')
                    a(target='_blank', rel='noopener noreferrer', :href='nw.orig || nw.full')
                        img.is-background(:src='nw.preview', @error='onErrorPreview')
                        .title
                            | {{nw.source}} : {{ nw.text }}
            .carousel-navigation.is-overlay
                .carousel-nav-left
                    i.fas.fa-chevron-left(aria-hidden='true')
                .carousel-nav-right
                    i.fas.fa-chevron-right(aria-hidden='true')
</template>

<script>
    import bulmaCarousel from 'bulma-extensions/bulma-carousel/dist/js/bulma-carousel.min'

    export default {
        props: ['news'],
        methods: {
            init() {
                var carousels = bulmaCarousel.attach();
            },
            onErrorIcon(e) {
                let defaultURL = '/assets/news/ic_rss.png';
                if (e.target.src !== defaultURL) {
                    e.target.src = defaultURL;
                }
            },
            onErrorPreview(e) {
                e.target.style.display = 'none';
            }
        },
        mounted() {
            this.init()
        }

    }
</script>
